import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import styled from 'styled-components'
import LayoutAnleitungen from '../components/layout/LayoutAnleitungen'

const StyledUl = styled.ul`
  li {
    list-style-type: none !important;
    font-size: 1.2rem !important;
  }
`

function stunden(props) {
  console.log('Anleitungen data', props)

  const stunden = props.data.allPrismicStunde.edges
  // Sortieren der Stunden nach Titel
  stunden.sort((a, b) => {
    let fa = a.node.data.title.text.toLowerCase(),
      fb = b.node.data.title.text.toLowerCase()

    if (fa < fb) {
      return -1
    }
    if (fa > fb) {
      return 1
    }
    return 0
  })

  return (
    <LayoutAnleitungen>
      <Row className="p-3">
        <Col xs={12}>
          <h2 className="mt-5">Stunden</h2>
          <StyledUl>
            {stunden.map((stunde) => {
              return (
                <li className="fs-3" key={stunde.node.url}>
                  <Link to={stunde.node.url}>
                    {stunde.node.data.title.text}
                  </Link>
                </li>
              )
            })}
          </StyledUl>
        </Col>
        </Row>
    </LayoutAnleitungen>
  )
}

export default stunden

export const query = graphql`
  query StundenQuery {
    allPrismicStunde {
      edges {
        node {
          data {
            title {
              text
            }
          }
          url
        }
      }
    }
  }
`
